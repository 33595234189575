// src/App.js
import React, { useEffect, useState } from 'react';
import './App.css';
import SearchBar from './SearchBar';

function App() {
  const [posts, setPosts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch("https://hermes-news.com/api/governance");
      const jsonData = await response.json();
      setPosts(jsonData.reverse());
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleString();
  };

  const handleSearch = query => {
    setSearchQuery(query);
  };

  let displayedPosts = [];
  if (searchQuery === '') {
    // Show the first 10 posts if no search query has been entered
    displayedPosts = posts.slice(0, 10);
  } else {
    // Filter the posts based on the search query
    displayedPosts = posts.filter(post =>
      post.info.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      post.coins[0].toLowerCase().includes(searchQuery.toLowerCase()) ||
      post.info.author.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }

  return (
    <div className="App">
      <h1>Crypto Forum Posts</h1>

      <SearchBar onSearch={handleSearch} />

      <div className="posts-container">
        {displayedPosts.map((item, index) => (
          <div key={index} className="posts-item">
            <div className="first-line">
              <a href={item.link} target="_blank" className="body">
                {item.info.title}
              </a> by{' '}
              <a href={item.info.author_url} target="_blank" rel="noopener noreferrer" className="author">
                {item.info.author}
              </a>
            </div>
            <div className="second-line">
              <span className="coin">{item.coins[0]}</span> - <span className="time">{formatTimestamp(item.time)}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
