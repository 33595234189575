import React, { useState } from 'react';

function SearchBar(props) {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = event => {
    setSearchQuery(event.target.value);
    props.onSearch(event.target.value);
  };

  return (
      <div className='search-bar'>
      <input
          type="text"
          value={searchQuery}
          onChange={handleSearch}
          placeholder="Search..."
      />
      </div>
  );
}

export default SearchBar;
